import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core"
import Lightbox from "react-image-lightbox"
import Layout from "../components/layout/main"
import Lead from "../components/global/text/lead"
import FiltreKullanimAlani from "../components/global/filtre/kullanimalani"
import ProjeyeGorePaslanmazFiltre from "../components/global/gatsby-image/projeyegorepaslanmazfiltre"
import LightboxImageWrapperCard from "../components/global/lightbox/lightbox-image-wrapper-card"
import Seo from "../components/global/seo/seo.jsx"
const PaslanmazHavalandirmaFiltresi = () => {
  const data = useStaticQuery(graphql`
    query {
      resim1: file(
        relativePath: { eq: "filtre-galeri/havalandirmafiltresi.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim2: file(
        relativePath: { eq: "filtre-galeri/havalandirmafiltresi1.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const fullImgArray = [
    data.resim1.childImageSharp.full.src,
    data.resim2.childImageSharp.full.src,
  ]
  const [isOpen, toggleOpenLightbox] = React.useState(false)
  const toggleLightbox = opened => {
    toggleOpenLightbox(opened)
  }
  const [imgIndex, setIndex] = React.useState(0)
  const setImgIndex = imageIndex => {
    setIndex(imageIndex)
  }
  const openLightBox = iIndex => {
    toggleLightbox(true)
    setImgIndex(iIndex)
  }
  const setPrevImgIndex = () => {
    const newIndex = (imgIndex + fullImgArray.length - 1) % fullImgArray.length
    setImgIndex(newIndex)
  }
  const setNextImgIndex = () => {
    const newIndex = (imgIndex + 1) % fullImgArray.length
    setImgIndex(newIndex)
  }
  return (
    <Layout>
      <Seo
        title="Paslanmaz Havalandırma Filtresi - Paslanmaz Filtre Erez Kaynak " 
        description="Firmamız proje çizimine yada numuneye göre müşterilerimizin istekleri doğrultusunda paslanmaz havalandırma filtresi imalatı yapmaktadır. Göz aralıkları mikronlar ile ölçülebilen mesh telleri kullanılarak istediğiniz ölçü, numune yada projeye göre paslanmaz havalandırma filtresi imalatını gerçekleştirmekteyiz."
        keywords="paslanmaz havalandırma filtresi,paslanmaz havalandırma filtresi imalatı,hava filtreleme sistemleri,hava filtresi imalatı,hava filtresi kullanım alanları,özel havalandırma filtreleri,havalandırma filtresi,paslanmaz havalandırma filtresi fiyatı,havalandırma filtresi fiyatı,özel havalandırma filtresi,paslanmaz filtre,havalandırma filtresi imalatı"
        url="paslanmaz-havalandirma-filtresi"
        imgurl={data.resim1.childImageSharp.thumb.src}
      />

      <Box my={3}>
        <Paper elevation={4}>
          <Box p={3}>
            <Typography component="h1" variant="h4">
              <Box textAlign="center" fontWeight={500} mt={3}>
                Paslanmaz Havalandırma Filtresi
              </Box>
            </Typography>
            <Lead>
              Firmamız proje çizimine yada numuneye göre müşterilerimizin
              istekleri doğrultusunda{" "}
              <span>paslanmaz havalandırma filtresi imalatı</span> yapmaktadır.
            </Lead>
            <Lead>
              Göz aralıkları mikronlar ile ölçülebilen mesh telleri kullanılarak
              istediğiniz ölçü, numune ya da projeye göre{" "}
              <span>paslanmaz havalandırma filtresi imalatını</span>{" "}
              gerçekleştirmekteyiz.
            </Lead>
            <Lead>
              Detaylı bilgi ya da fiyat teklifi almak için bizi arayınız.
            </Lead>
            <Box textAlign="center" pb={3}>
              <Link to="/iletisim" style={{ textDecoration: "none" }}>
                <Button variant="outlined" color="secondary">
                  İLETİŞİM BİLGİLERİ
                </Button>
              </Link>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box my={3}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div onClick={() => openLightBox(0)}>
              <LightboxImageWrapperCard
                lightbox={true}
                imgAlt="Paslanmaz Havalandırma Filtresi"
                fluid={data.resim1.childImageSharp.thumb}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div onClick={() => openLightBox(1)}>
              <LightboxImageWrapperCard
                lightbox={true}
                imgAlt="Paslanmaz Havalandırma Filtresi"
                fluid={data.resim2.childImageSharp.thumb}
              />
            </div>
          </Grid>
        </Grid>
      </Box>

      <Box my={3}>
        <Paper elevation={4}>
          <Box p={3}>
            <FiltreKullanimAlani align="center" />
          </Box>
        </Paper>
      </Box>

      <Box my={3}>
        <ProjeyeGorePaslanmazFiltre
          elevation
          card
          imgUrl="filtre4fluid"
          imgAlt="Projeye Göre Paslanmaz Filtre"
        />
      </Box>
      <Box my={3}>
        <ProjeyeGorePaslanmazFiltre
          elevation
          card
          imgUrl="filtre5fluid"
          imgAlt="Projeye Göre Paslanmaz Filtre"
        />
      </Box>
      {isOpen && (
        <Lightbox
          mainSrc={fullImgArray[imgIndex]}
          nextSrc={fullImgArray[(imgIndex + 1) % fullImgArray.length]}
          prevSrc={
            fullImgArray[
              (imgIndex + fullImgArray.length - 1) % fullImgArray.length
            ]
          }
          onCloseRequest={() => toggleLightbox(false)}
          onMovePrevRequest={setPrevImgIndex}
          onMoveNextRequest={setNextImgIndex}
        />
      )}
    </Layout>
  )
}
export default PaslanmazHavalandirmaFiltresi
